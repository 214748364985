import { useCallback } from 'react'

import type { LinxMetaHome } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

export const useLinxHomeView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const sendHomeViewEvent = useCallback(() => {
    const linxMetaHome: LinxMetaHome = {
      page: 'home',
    }

    return dispatchLinxEvent(linxMetaHome)
  }, [dispatchLinxEvent])

  return { sendHomeViewEvent }
}
