import { forwardRef } from 'react'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>

export const Container = forwardRef(
  (
    { className = '', ...otherProps }: Props,
    ref: React.LegacyRef<HTMLElement> | undefined
  ) => {
    return (
      <section
        className={`w-full max-w-[1280px] px-4 restructure-tablet:px-5 restructure-small-desktop:px-0 mx-auto ${className}`}
        {...otherProps}
        ref={ref}
      />
    )
  }
)

Container.displayName = 'Container'
